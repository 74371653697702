@import "src/styles";
.container {
  min-height: 100vh;
  width: 100vw;

  @include breakpoint-md {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .left {
    display: none;
    justify-self: end;
    grid-column: 1 / span 1;
    max-width: 2000px;

    @include breakpoint-md {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
    }

    .image {
      object-position: 50% 0;
    }
  }

  .right {
    overflow: auto;
    grid-column: 2 / span 1;
    align-self: stretch;
    min-height: 100vh;
  }

  .selectorContainer {
    padding: {
      top: $spacing-40;
      left: $spacing-24;
      right: $spacing-24;
      bottom: $spacing-40;
    }
    max-width: 600px;

    h1 {
      margin-bottom: $spacing-24;
      white-space: pre-wrap;
    }

    p {
      margin-bottom: $spacing-24;
    }

    @include breakpoint-lg {
      padding-left: $spacing-96;
    }
  }

  .logo {
    width: 110px;
    margin-bottom: $spacing-24;
    fill: $color-primary-main;
  }
}
